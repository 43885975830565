<template>
  <div>
    <div class="tw-flex tw-justify-center tw-items-center tw-px-4 ">
      <div
        class="tw-relative tw-rounded-md  tw-py-8 tw-pl-8"
      >
        <div
          :key="refreshKey"
          class="tw-flex tw-flex-col md:tw-flex-row md:tw-items-center"
        >
          <!-- one step -->
          <div class="tw-flex tw-flex-row tw-items-start md:tw-flex-col tw-gap-4">
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">
              <div class="tw-p-1">
                <profile-action :is-main-bio-dialog-open="false"></profile-action>
                <!-- <v-btn
                  fab
                  dark
                  small
                  :color="statusColor[`${visitStore.visitData.status[0].value}`]"
                  :to="{name:'apps-user-view'}"
                >
                  <v-icon color="white">
                    {{ icons.mdiFileDocumentEditOutline }}
                  </v-icon>
                </v-btn> -->
              </div>
              <div class="tw-my-1 tw-mx-0 lg:tw-mx-1 lg:tw-my-0 tw-w-0.5 tw-h-16 md:tw-h-0.5 md:tw-w-24 lg:tw-h-0.5 lg:tw-w-32 tw-bg-green-500 tw-rounded-md"></div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <p class="tw-text-xs tw-leading-3 tw-text-gray-600">
                STEP 01
              </p>
              <p class="tw-text-base tw-font-semibold tw-leading-none  tw-text-gray-800">
                Profile
              </p>
              <p>
                <v-chip
                  medium
                  :color="statusColor[`${visitStore.visitData.status[0].value}`]"
                  :class="`${statusColor[`${visitStore.visitData.status[0].value}`]}--text`"
                  class="v-chip-light-bg"
                  :to="{name:'apps-user-view'}"
                >
                  {{ statusLabel[visitStore.visitData.status[0].value] }}
                </v-chip>
              </p>
            </div>
          </div>

          <!-- two step -->

          <div class="tw-flex tw-flex-row tw-items-start md:tw-flex-col tw-gap-4">
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">
              <div class="tw-p-1">
                <!-- <v-btn
                  fab
                  dark
                  small
                  :color="statusColor[`${visitStore.visitData.status[1].value}`]"
                >
                  <v-icon color="white">
                    {{ icons.mdiLanPending }}
                  </v-icon>
                </v-btn> -->
                <!-- <initial-action
                  :is-dialog-visible-prop="isDialogVisibleProp"
                ></initial-action> -->
                <profile-action
                  :is-main-bio-dialog-open="false"
                  :emit-step="startingPoint"
                  :process-step="2"
                ></profile-action>
              </div>
              <div
                class="tw-my-1 tw-mx-0 lg:tw-mx-1 lg:tw-my-0 tw-w-0.5 tw-h-16 md:tw-h-0.5 md:tw-w-24 lg:tw-h-0.5 lg:tw-w-32 tw-bg-green-500 tw-rounded-md"
              ></div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <p class="tw-text-xs tw-leading-3 tw-text-gray-600">
                STEP 02
              </p>
              <p class="tw-text-base tw-font-semibold tw-leading-none tw-text-gray-800">
                Processing
              </p>
              <div>
                <p>
                  <v-chip
                    medium
                    :color="statusColor[`${visitStore.visitData.status[1].value}`]"
                    :class="`${statusColor[`${visitStore.visitData.status[1].value}`]}--text tw-text-black`"
                    class="v-chip-light-bg"
                    :to="visitStore.visitData.status[1].value === 'transfer'? {name: 'transfer'} : ''"
                  >
                    {{ statusLabel[visitStore.visitData.status[1].value] }}
                  </v-chip>
                </p>
              </div>
            </div>
          </div>
          <!-- <div v-if="visitStore.visitData.status[1].value === 'transfer'">
            <v-btn
              :to="{name: 'transfer'}"
            >
              Transmit Status
            </v-btn>
          </div> -->

          <!-- Transmit Button -->
          <div
            v-if="visitStore.visitData.status[1].value === 'transfer'"
            class="tw-flex tw-flex-row tw-items-start md:tw-flex-col tw-gap-4"
          >
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">
              <div class="tw-p-1">
                <v-btn
                  fab
                  dark
                  small
                  :color="statusColor[`${visitStore.faxStatus ? visitStore.faxStatus.status : 'warning'}`]"
                  :to="visitStore.visitData.status[1].value === 'transfer'? {name: 'transfer'} : ''"
                >
                  <v-icon color="white">
                    {{ icons.mdiFax }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <p class="tw-text-xs tw-leading-3 tw-text-gray-600">
                STEP 03
              </p>
              <p class="tw-text-base tw-font-semibold tw-leading-none tw-text-gray-800">
                Transmit
              </p>
              <div>
                <p>
                  <v-chip
                    medium
                    :color="statusColor[`${visitStore.visitData.status[2].value}`]"
                    :class="`${statusColor[`${visitStore.visitData.status[2].value}`]}--text`"
                    class="v-chip-light-bg"
                    :to="visitStore.visitData.status[1].value === 'transfer'? {name: 'transfer'} : ''"
                  >
                    {{ visitStore.faxStatus ? statusLabel[visitStore.faxStatus.status] : 'Pending' }}
                  </v-chip>
                </p>
                <!-- :class="`${statusColor[`${visitStore.faxStatus ? visitStore.faxStatus.status : 'warning'}`]}--text`" -->
                <!-- <v-btn
                  class="tw--ml-6"
                  elevation="5"
                  :color="statusColor[`${visitStore.faxStatus ? visitStore.faxStatus.status : 'warning'}`]"
                  :to="{name: 'transfer'}"
                >
                  <v-icon
                    left
                    color="white"
                  >
                    {{ icons.mdiCursorDefaultClickOutline }}
                  </v-icon>
                  {{ visitStore.faxStatus ? statusLabel[visitStore.faxStatus.status] : 'Pending' }}
                </v-btn> -->
              </div>
            </div>
          </div>

          <!-- three step -->

          <div
            v-if="visitStore.visitData.status[1].value !== 'transfer'"
            class="tw-flex tw-flex-row tw-items-start md:tw-flex-col tw-gap-4"
          >
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">
              <div class="tw-p-1">
                <v-btn
                  fab
                  dark
                  small
                  :color="statusColor[`${visitStore.visitData.status[2].value}`]"
                >
                  <v-icon

                    color="white"
                  >
                    {{ icons.mdiCreditCardOutline }}
                  </v-icon>
                </v-btn>
              </div>
              <div class="tw-my-1 tw-mx-0 lg:tw-mx-1 lg:tw-my-0 tw-w-0.5 tw-h-16 md:tw-h-0.5 md:tw-w-24 lg:tw-h-0.5 lg:tw-w-32 tw-bg-green-500 tw-rounded-md"></div>
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <p class="tw-text-xs tw-leading-3 tw-text-gray-600">
                STEP 03
              </p>
              <p class="tw-text-base tw-font-semibold tw-leading-none tw-text-gray-800">
                Payment
              </p>
              <div>
                <p>
                  <v-chip
                    medium
                    :color="statusColor[`${visitStore.visitData.status[2].value}`]"
                    :class="`${statusColor[`${visitStore.visitData.status[2].value}`]}--text`"
                    class="v-chip-light-bg"
                  >
                    {{ statusLabel[visitStore.visitData.status[2].value] }}
                  </v-chip>
                </p>
              </div>
            </div>
          </div>

          <!-- four step -->

          <div
            v-if="visitStore.visitData.status[1].value !== 'transfer'"
            class="tw-flex tw-flex-row tw-items-start md:tw-flex-col tw-gap-4"
          >
            <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-center">
              <div class="tw-p-1">
                <v-btn
                  fab
                  dark
                  small
                  :color="statusColor[`${visitStore.visitData.status[3].value}`]"
                >
                  <v-icon color="white">
                    {{ icons.mdiTruck }}
                  </v-icon>
                </v-btn>
              </div>
              <!-- <div class="tw-my-1 tw-mx-0 lg:tw-mx-1 lg:tw-my-0 tw-w-0.5 tw-h-16 md:tw-h-0.5 md:tw-w-24 lg:tw-h-0.5 lg:tw-w-32 tw-bg-green-500 tw-rounded-md"></div> -->
            </div>
            <div class="tw-flex tw-flex-col tw-items-start">
              <p class="tw-text-xs tw-leading-3 tw-text-gray-600">
                STEP 04
              </p>
              <p class="tw-text-base tw-font-semibold tw-leading-none tw-text-gray-800">
                Shipping
              </p>
              <div>
                <p>
                  <v-chip
                    medium
                    :color="statusColor[`${visitStore.visitData.status[3].value}`]"
                    :class="`${statusColor[`${visitStore.visitData.status[3].value}`]}--text`"
                    class="v-chip-light-bg"
                  >
                    {{ statusLabel[visitStore.visitData.status[3].value] }}
                  </v-chip>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
    mdiFileDocumentEditOutline, mdiLanPending, mdiCreditCardOutline, mdiTruck, mdiFax, mdiCursorDefaultClickOutline
} from '@mdi/js'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { useVisitStore } from '@/stores/Visit'
// eslint-disable-next-line import/named
import { statusColor, statusLabel } from '@/assets/lists/index'
import { visitService } from '@/api/index.js'
import initialAction from '@/components/Forms/initialAction.vue'
import profileAction from '@/components/Forms/profileAction.vue'

export default {
    name: 'RxOrderStatus',
    components: {
        initialAction,
        profileAction,
    },
    data() {
        return {
            isDialogVisibleProp: false,
            statusColor,
            statusLabel,
            icons: {
                mdiFileDocumentEditOutline,
                mdiLanPending,
                mdiCreditCardOutline,
                mdiTruck,
                mdiFax,
                mdiCursorDefaultClickOutline
            },
        }
    },
    computed: {
        startingPoint() {
            if (this.visitStore.visitData.status[0].value === 'complete') {
                return 4
            }

            return 1
        },
    },

    // mounted() {

    // },
    setup() {
        const visitStore = useVisitStore()
        const refreshKey = 0

        return {
            refreshKey,
            visitStore,
        }
    },

    // watch: {
    //     visitStore: {
    //         handler(newVal) {
    //             // console.log('🚀 ~ file: StatusActionStepper.vue ~ line 194 ~ handler ~ newVal', newVal)
    //             this.refreshKey += 1
    //         },
    //         deep: true,
    //     },
    // },
    async mounted() {
        this.$root.$on('updateMainBioDialog', data => {
            this.isMainBioDialogOpen = data
        })
        await this.loadData()
    },
    methods: {
        async loadData() {
            if (this.visitStore.patientData?.detailedAddress && (this.visitStore.patientData?.hasAllergy === 0 || this.visitStore.patientData?.hasAllergy === 1) && (this.visitStore.patientData?.takesMeds === 0 || this.visitStore.patientData?.takesMeds === 1)) {
                const data = {
                    basic: 'complete',

                    // processing: 'inprogress',
                    // payment: 'pending',
                    // delivery: 'pending',
                }
                await this.updateVisitStatus(data)
            }
        },
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                basic: data.basic || 'pending',

                // processing: data.processing || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
        },
    },
}
</script>
