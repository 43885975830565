<template>
  <div>
    <div class=" tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-strech tw-bg-gray-50 tw-py-8 md:tw-py-11 lg:tw-py-10 tw-px-4 md:tw-px-8 lg:tw-px-16">
      <div class="tw-grid tw-grid-cols-2 lg:tw-grid-cols-5 tw-gap-4">
        <div class="tw-col-span-2 lg:tw-col-span-3">
          <div class="tw-flex tw-items-center">
            <div class="tw-w-full">
              <h1 class="tw-text-3xl tw-text-gray-800 tw-leading-5">
                <!-- <strong class="primary--text">PharmaSmart</strong> Patient Portal -->
                <strong class="primary--text">Patient Portal</strong>
              </h1>
              <br />
              <h2 class="md:tw-text-2xl tw-leading-9 tw-font-bold tw-text-gray-800">
                <!-- Active Visit: <strong class="tw-text-3xl success--text">{{ visitStore.visitData.rxDate }}</strong> -->
                <v-row>
                  <v-col
                    class="d-flex"
                    cols="12"
                    sm="6"
                    lg="4"
                  >
                    <!-- Active Visit: &nbsp &nbsp -->
                    <v-select
                      v-model="selectedIndex"
                      :prepend-inner-icon="icons.mdiPrescription"
                      background-color="fieldBg"
                      color="primary"
                      :items="visitList"
                      item-color="success"
                      label="Active Visit: "
                      item-text="rxDate"
                      item-value="id"
                      success
                      outlined
                      @change="changeVisit"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-alert
                      v-if="!$vuetify.breakpoint.mdAndDown &&( visitStore.visitData.status[1].value === 'inprogress' || visitStore.visitData.status[1].value === 'complete' || visitStore.visitData.status[1].value === 'transfer')"
                      :color="statusColor[this.visitStore.visitData.status[1].value]"
                      dark
                    >
                      <div class="d-flex tw-justify-center">
                        <span class=" tw-font-bold tw-text-white tw-text-md">
                          {{ this.messageList[this.visitStore.visitData.status[1].label][this.visitStore.visitData.status[1].value].status }}</span>
                      </div>
                    </v-alert>
                    <!-- <v-alert
                      v-if="visitStore.visitData.status[1].value === 'transfer' && visitStore.faxStatus.status === 'success'"
                      color="warning"
                      dark
                    >
                      <div class="d-flex tw-justify-center">
                        <span class=" tw-font-bold tw-text-white tw-text-md">
                          This prescription was transmitted to another pharmacy!</span>
                      </div>
                    </v-alert> -->
                  </v-col>
                </v-row>
              </h2>
              <br v-if="!$vuetify.breakpoint.mdAndDown">
              <!-- v-if="this.visitStore.visitData.status[1].value !=='inprogress' && this.visitStore.visitData.status[1].value !=='transfer' && this.visitStore.visitData.status[2].value !=='inprogress' && (this.visitStore.visitData.status[1].value !== 'complete' && this.visitStore.visitData.status[2].value !== 'pending')" -->
              <h2
                v-if="this.visitStore.visitData.status[0].value ==='pending' & this.visitStore.visitData.status[1].value ==='pending' & this.visitStore.visitData.status[2].value ==='pending' & this.visitStore.visitData.status[3].value ==='pending' "
                class="tw-text-xl md:tw-text-3xl tw-leading-9 tw-font-bold tw-text-gray-800"
              >
                Complete your virtual dermatology care with <strong class="error--text">Free</strong> prescription delivery & <strong class="error--text">Free</strong> skincare samples
              </h2>

              <div v-if="this.visitStore.visitData.status[1].value ==='inprogress' || this.visitStore.visitData.status[1].value ==='transfer' || (this.visitStore.visitData.status[1].value === 'complete' && this.visitStore.visitData.status[2].value === 'pending') || (this.visitStore.visitData.status[0].value === 'complete' && this.visitStore.visitData.status[1].value === 'pending') ">
                <h2
                  class="tw-text-xl md:tw-text-3xl tw-leading-9 tw-font-bold tw-text-gray-800"
                >
                  {{ this.messageList[this.visitStore.visitData.status[1].label][this.visitStore.visitData.status[1].value].text }}
                </h2>
                <br>
                <div v-html="this.messageList[this.visitStore.visitData.status[1].label][this.visitStore.visitData.status[1].value].subText"></div>
              </div>
              <!-- payment -->
              <div v-if="this.visitStore.visitData.status[2].value ==='inprogress'">
                <h2
                  class="tw-text-xl md:tw-text-3xl tw-leading-9 tw-font-bold tw-text-gray-800"
                >
                  {{ this.messageList[this.visitStore.visitData.status[2].label][this.visitStore.visitData.status[2].value].text }}
                </h2>
                <br>
                <div v-html="this.messageList[this.visitStore.visitData.status[2].label][this.visitStore.visitData.status[2].value].subText"></div>
              </div>
              <form-dialog-btn :is-main-bio-dialog-open-prop="isMainBioDialogOpen"></form-dialog-btn>
            </div>
          </div>
          <div
            id="home-content2"
            class="tw-px-2"
          >
            <status-action-stepper></status-action-stepper>
          </div>
        </div>
        <div
          id="bannerImage"
          class="tw-pl-12 tw-col-span-2 lg:tw-col-span-2 "
        >
          <v-img
            src="@/assets/images/banners/onlinePharmacy.png"
            alt="Online Pharmacy"
            contain
            role="img"
          />
        </div>
        <div
          class="tw-col-span-2 lg:tw-col-span-3"
        >
        </div>
        <!-- <div
          v-if="visitStore.visitData.status[1].value === 'pending' || visitStore.visitData.status[1].value === 'transfer'"
          class="tw-pl-12 tw-col-span-2 lg:tw-col-span-2 "
        >
          <p class="tw-text-gray-800">
            <i>Want to use another pharmacy?</i> <router-link to="transfer">
              Click here
            </router-link>
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mdiArrowRight, mdiPrescription } from '@mdi/js'
import { ref } from '@vue/composition-api'
import StatusActionStepper from '@/components/UI/StatusActionStepper.vue'
import formDialogBtn from '@/components/Forms/formDialogBtn.vue'
import { useVisitStore } from '@/stores/Visit.js'
import { visitService } from '@/api/index.js'
import { messageList, statusColor } from '@/assets/lists'

// import { transferCard } from '@/components/UI/index'

export default {
    name: 'HomeBanner',
    components: {
        StatusActionStepper,
        formDialogBtn,

        // transferCard
    },
    props: {
        isMainBioDialogOpen: {
            type: Boolean,
            default: true,
        },
    },
    async mounted() {
        await this.getAllVisists()
        this.refreshState()
        this.timer = setInterval(this.refreshState, 30000)
    },
    setup() {
        const visitList = ref([])
        const selectedIndex = ref('')
        const visitStore = useVisitStore()
        const timer = ref(null)

        return {
            statusColor,
            messageList,
            visitStore,
            selectedIndex,
            visitList,
            timer,
            icons: {
                mdiArrowRight,
                mdiPrescription
            },
        }
    },
    destroyed() {
        this.cancelAutoUpdate()
    },
    methods: {
        async loadData() {
            if (this.visitStore.patientData?.detailedAddress && (this.visitStore.patientData?.hasAllergy === 0 || this.visitStore.patientData?.hasAllergy === 1) && (this.visitStore.patientData?.takesMeds === 0 || this.visitStore.patientData?.takesMeds === 1) && (this.visitStore.patientData?.insuranceStatus === 0 || this.visitStore.patientData?.insuranceStatus === 1)) {
                const data = {
                    basic: 'complete',

                // processing: 'inprogress',
                // payment: 'pending',
                // delivery: 'pending',
                }
                await this.updateVisitStatus(data)
            }
        },
        async updateVisitStatus(data) {
            const statusData = {
                dateofBirth: this.visitStore.patientData.dateofBirth,
                visitId: this.visitStore.visitId,
                patientId: this.visitStore.patientData.id,
                basic: data.basic || 'pending',

                // processing: data.processing || 'pending',
                // payment: data.payment || 'pending',
                // delivery: data.delivery || 'pending',
            }
            await visitService.updateVisitStatus(statusData)
            await this.visitStore.setVisit({ id: statusData.visitId, dob: statusData.dateofBirth })
        },
        async changeVisit(data) {
            console.log('🚀 ~ file: homePageBanner.vue ~ line 130 ~ changeVisit ~ data', data)
            await this.visitStore.setVisit({ id: this.selectedIndex, dob: this.visitStore.dob })
            this.$root.$emit('alert', {
                type: 'success',
                message: 'Visit changed successfully',
                timer: 1000,
            })
        },
        async getAllVisists() {
            if (typeof this.visitStore.patientData.id === 'undefined') {
                setTimeout(async () => {
                    await this.visitStore.ptVisitList({ patientId: this.visitStore.patientId, dob: this.visitStore.dob })
                    this.visitList = this.visitStore.visitList
                    this.selectedIndex = this.visitStore.visitId

                    // this.visitList = await visitService.getAllVisitsByPt({ patientId: this.visitStore.patientData.id })
                    // if (this.selectedIndex !== -1) {
                    //     this.selectedVisit = [this.visitList.data[this.selectedIndex]]
                    // }
                }, 500)
            }
            if (typeof this.visitStore.patientData.id !== 'undefined') {
                await this.visitStore.ptVisitList({ patientId: this.visitStore.patientId, dob: this.visitStore.dob })
                this.visitList = this.visitStore.visitList
                this.selectedIndex = this.visitStore.visitId

                // if (this.selectedIndex !== -1) {
                //     this.selectedVisit = [this.visitList.data[this.selectedIndex]]
                // }
            }
        },
        async refreshState() {
            await this.loadData()
            this.visitStore.setVisit({ id: this.visitStore.visitId, dob: this.visitStore.dob })
        },
        cancelAutoUpdate() {
            clearInterval(this.timer)
        }
    }
}
</script>
<style lang="scss" scoped>
.row.match-height {
  .v-card {
    height: 100%;
  }
}
#bannerImage {
    align-self: center;
}

</style>
